@import "~@dev-spendesk/grapes/dist/theme/main";
@import "../../index.scss";

.BottomBar {
  @extend %elevation-30;
  position: fixed;
  z-index: 8000;
  bottom: 0;
  right: 0;
  width: calc(100% - #{$aside-width});
  background-color: $color-white;
}

.BottomBar__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 88px;
  margin: 0 80px;
  max-width: $layout-max-width;
}

.BottomBar__primary {
  display: flex;
  align-items: center;
}

.BottomBar__submit {
  margin-right: $spacing-xs;
}
