@import "~@dev-spendesk/grapes/dist/theme/main";
@import "../../index.scss";

.AsideBar {
  height: 100vh;
  width: $aside-width;
  min-width: $aside-width;
  background: radial-gradient(
    91.42% 90.61% at -5.49% 79.49%,
    #{$color-primary-light},
    #{$color-primary}
  );
  overflow-y: auto;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
}

.AsideBar__container {
  text-align: center;
  color: $color-white;
  flex: 2;
}

.AsideBar__logo {
  margin: 45px 55px;
  width: 135px;
}

.AsideBar__illustration {
  display: block;
  width: 183px;
  margin: 0 auto;
}

.AsideBar__title {
  @extend %title-xl;
  padding-bottom: $spacing-s;
  padding-top: $spacing-3xl;
}

.AsideBar__desc {
  @extend %body-m;
  padding: 0 $spacing-m;
}
