@import "~@dev-spendesk/grapes/dist/theme/main";

.LayoutDefault {
  display: flex;
}

.LayoutDefault__main {
  position: relative;
  flex-grow: 1;
  padding: 48px 80px 120px 80px;
  background-color: $color-page-background;
  overflow-y: auto;
}
