@import "~@dev-spendesk/grapes/dist/theme/main";

.Supervision {
  background-color: $color-alert;
  padding: 5px;
  color: $color-white;
  text-align: center;
  width: 100%;
}

.Supervision__logout {
  color: $color-white;
}
