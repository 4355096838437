@import "~@dev-spendesk/grapes/dist/theme/main";
@import "../../index.scss";

.ExtraPage__container {
  max-width: $layout-max-width;
}

.ExtraPage__header {
  @extend %body-xxl;
  color: $color-complementary;
  margin-bottom: $spacing-3xl;
}
