@import "~@dev-spendesk/grapes/dist/theme/main";

.Field__content {
  display: flex;
  flex-direction: column;
  text-align: start;

  & > * {
    margin-top: $spacing-m;
  }
}
