@import "~@dev-spendesk/grapes/dist/theme/main";

@import "./styles/reset";
@import "./styles/fonts";

$layout-max-width: 920px;
$aside-width: 280px;

body {
  @extend %body-m;
  font-family: "GT-America", Helvetica, sans-serif;
  overflow-y: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
