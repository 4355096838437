@font-face {
  font-family: 'GT-America';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('./fonts/GT-America/GT-America-Standard-Regular.woff2')
      format('woff2'),
    url('./fonts/GT-America/GT-America-Standard-Regular.woff') format('woff');
}

@font-face {
  font-family: 'GT-America';
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src: url('./fonts/GT-America/GT-America-Standard-Regular-Italic.woff2')
      format('woff2'),
    url('./fonts/GT-America/GT-America-Standard-Regular-Italic.woff')
      format('woff');
}

@font-face {
  font-family: 'GT-America';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url('./fonts/GT-America/GT-America-Standard-Medium.woff2')
      format('woff2'),
    url('./fonts/GT-America/GT-America-Standard-Medium.woff') format('woff');
}

@font-face {
  font-family: 'GT-America';
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url('./fonts/GT-America/GT-America-Standard-Medium-Italic.woff2')
      format('woff2'),
    url('./fonts/GT-America/GT-America-Standard-Medium-Italic.woff')
      format('woff');
}

@font-face {
  font-family: 'Patron';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('./fonts/Patron/Patron-Regular.woff2') format('woff2'),
    url('./fonts/Patron/Patron-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Patron';
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src: url('./fonts/Patron/Patron-Italic.woff2') format('woff2'),
    url('./fonts/Patron/Patron-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Patron';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url('./fonts/Patron/Patron-Medium.woff2') format('woff2'),
    url('./fonts/Patron/Patron-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Patron';
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url('./fonts/Patron/Patron-MediumItalic.woff2') format('woff2'),
    url('./fonts/Patron/Patron-MediumItalic.woff') format('woff');
}
