@import "~@dev-spendesk/grapes/dist/theme/main";

@keyframes loading {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.FileField__input {
  display: none;
}

.FileField__label {
  position: relative;
  @extend %elevation-30;
  @extend %title-m;
  cursor: pointer;
  height: 36px;
  padding: 0 $spacing-s;
  border-radius: $border-radius-xxs;
  background-color: $color-primary;
  color: $color-white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 0;

  &:active {
    @extend %elevation-20;
    background-color: $color-primary-dark;
  }

  &::before {
    content: "";
    position: absolute;
    border-radius: $border-radius-xxs;
    z-index: $z-index-negative;
    width: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background: $color-primary-dark;
  }
}

.FileField__loading {
  cursor: progress;

  &::before {
    animation: loading 1s infinite;
  }
}

.FileField__filename {
  text-decoration: underline;
}

.FileField__preview {
  max-width: 100%;
}
