@import "~@dev-spendesk/grapes/dist/theme/main";

.FieldLayout__container {
  display: flex;
  gap: $spacing-xs;
  background: $color-white;
  padding: 0 $spacing-s;
  justify-items: flex-start;
  align-items: center;
  height: $spacing-3xl;
  border: 1px solid $color-neutral-light;
  border-top: none;

  &:first-of-type {
    border-top: 1px solid $color-neutral-light;
    border-top-left-radius: $border-radius-xs;
    border-top-right-radius: $border-radius-xs;
  }

  &:last-of-type {
    border-bottom-left-radius: $border-radius-xs;
    border-bottom-right-radius: $border-radius-xs;
  }
}

.FieldLayout__text {
  flex: 1;
}

.FieldLayout__label {
  @extend %body-m;
  color: $color-complementary;
}

.FieldLayout__caption {
  display: flex;
  align-items: center;
}

.FieldLayout__description {
  @extend %body-s;
  color: $color-neutral-dark;
  margin-right: $spacing-xs;
}
